// import "core-js/stable";
// import "@stimulus/polyfills";
import { Application } from "stimulus";
// import Turbolinks from "turbolinks";

// Turbolinks.start();
// FIXME: this is breaking js from rendering
// Turbolinks.setProgressBarDelay(100);
const application = Application.start();

export default application;
