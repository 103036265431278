import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['primaryLink']
  get mainMenu() {
    return document.querySelector('.main-menu')
  }
  
  connect() {
    this.state = {
      defaultBackground: '',
      backgroundImages: [],
    }
  }

  swapBackground() {
    let backgroundImage = this.element.dataset.bg;

    if (!backgroundImage) {
      backgroundImage = this.state.defaultBackground;
    }

    this.mainMenu.style.backgroundImage = `url('${backgroundImage}')`;
  }
}
